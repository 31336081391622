import { Grid2 as Grid, Stack, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Paper from 'components/@extended/Paper'
import React from 'react'

import { NumericFormat } from 'react-number-format'

import { useCreatePlanContext } from '../../contexts/CreatePlanContextProvider'

function PlanComponentSummary() {
  const theme = useTheme()

  const { totalComponentsInPlanValue, maxVariableAllowedInComponents, plan, mode } = useCreatePlanContext()

  React.useEffect(() => {}, [maxVariableAllowedInComponents, plan, plan.variableCap])

  return mode === 'REVIEW' ? (
    <></>
  ) : (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Paper sx={{ marginTop: 5 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid size={8}>
                <Typography variant="h3" sx={{ pl: 2, pb: 2, pt: 0 }}>
                  Plan Configuration Status
                </Typography>
              </Grid>
              <Grid size={4}>
                <Stack margin={2} spacing={1} sx={{ pl: 0, pb: 1, pt: 0, pr: 1 }}>
                  <NumericFormat
                    value={totalComponentsInPlanValue}
                    thousandSeparator
                    decimalScale={1}
                    suffix="%"
                    customInput={TextField}
                    label="Component Percentage Total"
                    variant="filled"
                    helperText={totalComponentsInPlanValue === 100 ? '' : 'Must total 100%'}
                    InputProps={{
                      readOnly: true,
                      style: {
                        backgroundColor:
                          totalComponentsInPlanValue === 100
                            ? `${theme.palette.success.lighter}`
                            : `${theme.palette.error.main}`,
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default PlanComponentSummary
